import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// const yearNow = new Date().getFullYear();

const Tweets = ({
  config: {
    tokenName,
    bgColor,
    socials
  },
}) => {
  const bp500 = useMediaQuery("(min-width: 500px)");

  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: !bp500 ? '6.25rem 1.25rem 6rem 1.25rem' : '6rem 3.25rem 6rem 3.25rem',
        backgroundColor: bgColor,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        {socials.links.map((social, idx) => {
          const SocialIcon = social.iconComponent;

          return (
            <IconButton 
              component={Link}
              variant="linkIcon1"
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              title={social.title}
              key={`item${idx}`}
              sx={{
                marginTop: bp500 ? '1rem' : '0.75rem',
                marginLeft: idx !== 0 ? bp500 ? '1rem' : '0.75rem' : 0,
              }}
            >
              <SocialIcon />
            </IconButton>
          );
        })}
      </Box>
      <Typography 
        variant="body2"
        sx={{
          textAlign: 'center',
          width: '100%',
          maxWidth: '54rem',
          marginTop: '2rem',
        }}
      >
        <Link href="/docs/Andy-On-Base-Whitepaper-V1.pdf" variant="link1" rel="noopener noreferrer" target="_blank">Whitepaper</Link>
      </Typography>
      <Typography 
        variant="body2"
        sx={{
          textAlign: 'center',
          width: '100%',
          maxWidth: '54rem',
          marginTop: '2rem',
        }}
      >
        {tokenName} is a meme token with no intrinsic value or expectation of financial return.<br />The token is for entertainment purposes only.<br /><br />Email: info@AndyToken.fun<br /><br />Contract address: 0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022
      </Typography>
    </Box>
  )
};

export default Tweets;
